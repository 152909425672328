<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface IProps {
    blok: LandingImageCarouselHeroBlokInterface;
}
const props = defineProps<IProps>();

const imageSlide1 = computed(() => `url('${props.blok.carouselImages?.[0]?.image?.filename}')`);
const imageSlide2 = computed(() => `url('${props.blok.carouselImages?.[1]?.image?.filename}')`);
const imageSlide3 = computed(() => `url('${props.blok.carouselImages?.[2]?.image?.filename}')`);
const imageSlide4 = computed(() => `url('${props.blok.carouselImages?.[3]?.image?.filename}')`);
</script>

<template>
    <es-container
        v-editable="blok"
        class="outer-landing px-100"
        fluid>
        <es-row class="px-lg-50">
            <es-col
                xl="auto"
                xxl="auto"
                class="pl-0 pr-50">
                <div class="left-panel bg-dark-blue rounded-sm h-100" />
            </es-col>
            <es-col
                cols="12"
                lg="6"
                xl=""
                xxl=""
                class="hero-zip-panel bg-radial-gradient-blue-900-to-300 px-100">
                <div class="py-200 py-lg-300 px-50 px-lg-100 d-flex flex-column justify-content-center h-100">
                    <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
                    <h1
                        v-if="blok.heading"
                        class="font-size-600 font-size-lg-700 font-weight-bolder text-white text-left text-md-center text-lg-left">
                        <line-break-text :text="blok.heading" />
                    </h1>
                    <p
                        v-if="blok.subtitle"
                        class="font-size-100 font-size-lg-300 text-white text-left text-md-center text-lg-left">
                        {{ blok.subtitle }}
                    </p>
                    <storyblok-component
                        v-if="blok.zipCodeForm?.length"
                        :blok="blok.zipCodeForm[0] as unknown as SbBlokData"
                        :dark="true"
                        class="justify-content-center justify-content-lg-start"
                        input-id="hero-zip-code" />
                </div>
            </es-col>
            <es-col
                role="region"
                :aria-label="blok.carouselRegionAccessibleLabel"
                class="d-none d-lg-block"
                lg="6">
                <es-row>
                    <es-col class="carousel-container position-relative pl-50 pr-0">
                        <es-carousel
                            v-if="blok.carouselImages?.length"
                            ref="carousel"
                            :auto-play="blok.carouselAutoplay"
                            :auto-play-interval="Number(blok.carouselAutoplaySpeed || 4) * 1000"
                            circular
                            class="rounded-sm overflow-hidden"
                            :items="blok.carouselImages"
                            :num-visible="1">
                            <template #item="{ item }">
                                <storyblok-component :blok="item" />
                            </template>
                        </es-carousel>
                    </es-col>
                </es-row>
            </es-col>
        </es-row>
    </es-container>
</template>

<style scoped lang="scss">
@use 'sass:map';
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';
@use '@energysage/es-ds-styles/scss/variables';

.bg-radial-gradient-blue-900-to-300 {
    background: radial-gradient(118.86% 123.26% at 73.16% 100%, variables.$blue-300 0%, variables.$blue-900 100%);
}

.left-panel {
    min-width: variables.$spacer * 2;

    @include breakpoints.media-breakpoint-up(xl) {
        width: calc(50vw - (map.get(variables.$container-max-widths, xl) / 2) - (variables.$spacer * 2));
    }
    @include breakpoints.media-breakpoint-up(xxl) {
        width: calc(50vw - (map.get(variables.$container-max-widths, xxl) / 2) - (variables.$spacer * 2));
    }
}

@include breakpoints.media-breakpoint-up(lg) {
    .outer-landing {
        background-color: variables.$white;
    }

    .hero-zip-panel {
        border-radius: variables.$border-radius-sm;
    }
}

:deep(.es-carousel-arrow) {
    /* use !important to override the EsCarousel style of equal specificity that sometimes wins */
    align-items: center;
    background: variables.$carousel-control-color !important;
    border-radius: variables.$border-radius-sm;
    bottom: 1rem !important;
    box-shadow: none !important;
    color: variables.$dark-blue !important;
    display: flex;
    height: 2rem !important;
    justify-content: center;
    left: unset !important;
    opacity: variables.$carousel-control-hover-opacity;
    position: absolute;
    top: unset;
    transform: revert !important;
    width: 2rem;
    z-index: 1;

    svg {
        height: 1.125rem !important;
        width: 1.125rem !important;
    }

    &:hover {
        background-color: map.get(variables.$button-hover-colors, 'outline-primary') !important;
    }

    &:focus {
        background-color: map.get(variables.$button-focus-border-colors, 'outline-dark-bg') !important;
        outline: 1px solid variables.$dark-blue;
    }

    &:not(:disabled):not(.disabled):active {
        background-color: map.get(variables.$button-active-colors, 'outline-primary') !important;
        outline: 1px solid variables.$dark-blue;
        transform: revert;
    }

    &::before {
        all: revert;
    }
}

:deep(.es-carousel-prev-arrow) {
    /* use !important to override the EsCarousel style of equal specificity that sometimes wins */
    left: 2rem !important;
}

:deep(.es-carousel-next-arrow) {
    right: 1.5rem;
}

.carousel-container {
    /* make room for the dots so the left panel is the correct height */
    margin-bottom: 8.5rem;
}

:deep(.es-carousel-dots) {
    bottom: -8.5rem;
    /* override the EsCarousel style of equal specificity that sometimes wins */
    gap: 0.5rem !important;
    left: 0;
    /* override the utility class applied by EsCarousel */
    margin: 0 !important;
    /* override the EsCarousel style of equal specificity that sometimes wins */
    padding-left: 0.5rem !important;
    position: absolute;
    right: 0;

    .es-carousel-dot {
        flex-grow: 1;
        margin: 0;

        button {
            background-position: center center;
            background-size: cover;
            border-radius: 0.5rem;
            display: block;
            height: 8rem;
            width: 100%;

            @media not (prefers-reduced-motion) {
                transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
            }

            &:focus,
            &:hover {
                box-shadow:
                    0 0 0 2px variables.$blue-100 inset,
                    0 5px 10px 0 variables.$blue-100;
                opacity: 1;
            }
        }

        &[data-p-highlight='true'] button {
            box-shadow: 0 0 0 2px variables.$blue-700 inset;
        }

        &:nth-child(1) button {
            background-image: v-bind(imageSlide1);
        }

        &:nth-child(2) button {
            background-image: v-bind(imageSlide2);
        }

        &:nth-child(3) button {
            background-image: v-bind(imageSlide3);
        }

        &:nth-child(4) button {
            background-image: v-bind(imageSlide4);
        }
    }
}
</style>
